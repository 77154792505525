<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="timesheets"
      class="elevation-"
      disable-pagination
      hide-default-footer
      v-model="selectedTasks"
      show-select
      :options.sync="options"
      :server-items-length="total"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:no-data>
        Aucune demande a été faite
      </template>
      <template v-slot:item.start_date="{ item }">
        {{ item.start_date | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.end_date="{ item }">
        {{ item.end_date | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:top>
        <v-toolbar  flat extended extension-height="100">
          <v-toolbar-title id="toolbar-title">Timesheets ignorés</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <Button v-bind="attrs" v-on="on" class="mb-2" action_name="Récupérer" color="primary" text @clicked="dialog = true" />
              <span class="mr-5 total-hours"><em class="total">Total : </em>{{ hours }} heures</span>
            </template>
            <v-card>
              <v-card-title class="headline">Etes vous sûr de récuperer ce(s) rapport(s) ?</v-card-title>
              <v-card-text> </v-card-text>
              <v-card-actions class="btn-actions">
                <v-btn color="dark darken-5" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="secondary darken-5" text @click="handleRecover">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="filterDialog" max-width="836px">
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">Filtre</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete id="agent" v-model="editedItem.agent" :items="agents" label="Agent" item-text="name" return-object multiple deletable-chips small-chips />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete id="department" v-model="editedItem.department" :items="departments" label="Département" multiple deletable-chips small-chips />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pb-0"  v-if="!disabledExactDate">
                      <label>Date</label>
                      <v-checkbox dense hide-details class="ma-0 pa-0 d-inline-block select-multiple-date" v-model="checkDate" @change="handleMultipleDate(true)"></v-checkbox>
                      <custom-date-picker v-model="editedItem.exact_date" />
                    </v-col>
                    <v-col cols="12" md="3" sm="6" class="ma-0 pb-0" v-if="showMultipleDates">
                      <label>Date début</label>
                      <v-checkbox dense hide-details class="ma-0 pa-0 d-inline-block select-multiple-date" v-model="checkDate" @change="handleMultipleDate(false)"></v-checkbox>
                      <custom-date-picker v-model="editedItem.start_date" />
                    </v-col>
                    <v-col cols="12" md="3" sm="6" class="ma-0 pb-0" v-if="showMultipleDates">
                      <label>Date fin</label>
                      <custom-date-picker v-model="editedItem.end_date" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <Button action_name="Filtrer" color="#86CC26" @clicked="save" style="width: 220px" class="ml-5 mr-5 mb-5" :loading="loadingFilter"/>
                <span class="mb-5" style="color:#B2BBAB" @click="handleReset">Réinitialiser</span>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template v-slot:extension>
            <div class="filter-block">
              <div>
                <template v-for="(agent, index) in editedItem.agent">
                  <v-chip class="ma-1" :key="agent.name" close @click:close="handleChipDelete(index, 'agent')">Agent:{{ agent.name }}</v-chip>
                </template>
                <template v-for="(department, index) in editedItem.department">
                  <v-chip class="ma-1" :key="department" close @click:close="handleChipDelete(index, 'department')">Departement:{{ department }}</v-chip>
                </template>
                <template v-if="editedItem.exact_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('exact_date')">Date:{{ editedItem.exact_date }}</v-chip>
                </template>
                <template v-if="editedItem.start_date && editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleMultipleDateDelete">Entre le :{{ editedItem.start_date }} et le : {{ editedItem.end_date }}</v-chip>
                </template>
                <template v-if="editedItem.start_date && !editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('start_date')">Aprés le :{{ editedItem.start_date }}</v-chip>
                </template>
                <template v-if="editedItem.end_date && !editedItem.start_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('end_date')">Avant le :{{ editedItem.end_date }}</v-chip>
                </template>
              </div>
              <div>
                <v-chip  class="chip-delete">
                  <v-icon @click="filterDialog = true" outlined>mdi-filter</v-icon>
                </v-chip>
              </div>
            </div>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.name="{ item }">
        <Avatar :item="item" />
        <span class="ml-4">{{ item.name }}</span>
      </template>
      <template v-slot:item.total="{ item }">
        <span>{{ item.total }}</span>
        <span v-if="!item.total">0</span>
      </template>
      <template v-slot:item.time_sheet_id="{ item }">
        <v-icon v-if="item.time_sheet_id == null" class="outlined inactive-status" >mdi-close</v-icon>
        <v-icon v-if="item.time_sheet_id != null" class="outlined active-status" >mdi-check</v-icon>
      </template>
      <template v-slot:item.total="{ item }">{{ item.total | moment_time }}</template>
    </v-data-table>
  </v-container>
</template>
<script>
import { Button, Avatar, AutoComplete, CustomDatePicker } from "@/components/base";

import { getAllTimeSheetIgnoredByAdmin, recoverTimeSheet } from "@/services/timesheet.api";
import departments from "@/assets/data/departments.json";
import { getAllActiveUsers } from "@/services/users.api";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";

export default {
  components: { Button, Avatar, AutoComplete, CustomDatePicker },
  mixins: [infinityScrollMixin],
  data: () => ({
    loadingFilter: false,
    disabledExactDate: false,
    showMultipleDates: false,
    checkDate: false,
    total:0,
    options: {},
    agents:[],
    filterDialog:false,
    selectedTasks: [],
    totalHours: 0,
    items: [],
    dialog: false,
    departments: departments,
    headers: [
      {
        text: "Agent",
        align: "start",
        sortable: true,
        value: "name"
      },
      { text: "Statut de la demande", value: "time_sheet_id", align: "center" },
      { text: "Date du rapport ", value: "start_date" },
      { text: "Date d'expiration ", value: "end_date" },
      { text: "Total", value: "total" }
      // { text: "Actions", value: "actions", sortable: false }
    ],
    timesheets: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    pageNumber: 1,
    last_page: 0,
    totalDays:0
  }),

  computed: {
    hours() {
      return this.total * 24;
    }
  },
  watch: {
    options: {
      handler () {
        this.editedItem.order_by = this.options.sortBy[0]
        this.editedItem.order_by_direction = this.options.sortDesc[0]
        this.timesheets=[]
        this.pageNumber = 1
        getAllTimeSheetIgnoredByAdmin(this.pageNumber, this.editedItem).then(response => {
          this.timesheets = response.data.data;
          this.last_page = response.data.last_page;
          this.total=response.data.total
        });
      },
      deep: true,
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1;
        getAllTimeSheetIgnoredByAdmin(this.pageNumber, this.editedItem).then(response => {
          this.timesheets = [...this.timesheets, ...response.data.data]
          this.total=response.data.total
        });
      }
    },
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    handleMultipleDateDelete() {
      this.editedItem["start_date"] = "";
      this.editedItem["end_date"] = "";
      this.save();
    },
    handleMultipleDate(status) {
      if (status === true) {
        this.editedItem["exact_date"] = "";
      } else {
        this.editedItem["start_date"] = "";
        this.editedItem["end_date"] = "";
      }
      this.showMultipleDates = !this.showMultipleDates;
      this.disabledExactDate = !this.disabledExactDate;
    },
    handleDateDelete(key) {
      this.editedItem[key] = "";
      this.save();
    },
    handleChipDelete(index, key) {
      this.editedItem[key].splice(index, 1);
      this.save();
    },
    handleReset() {
      this.editedItem = {};
      this.save();
    },
    save() {
      this.loadingFilter=true;
      this.pageNumber = 1;
      getAllTimeSheetIgnoredByAdmin(this.pageNumber, this.editedItem).then(response => {
        this.loadingFilter=false;
        this.timesheets = response.data.data;
        this.last_page = response.data.last_page;
        this.total=response.data.total
        this.filterDialog = false;
      });
    },
    handleRecover() {
      const selectedRecover = { id: [] };
      for (let i in this.selectedTasks) {
        selectedRecover.id.push(this.selectedTasks[i].id);
      }
      recoverTimeSheet(selectedRecover).then(() => {
        this.$swal.fire({ title: "Rapport récupéré avec succès !"});
        this.dialog = false;
        for (let i in this.selectedTasks) {
          this.editedIndex = this.timesheets.indexOf(i);
          this.timesheets.splice(this.editedIndex, 1);
        }
      });
    },
    initialize() {
      getAllTimeSheetIgnoredByAdmin(this.pageNumber, this.editedItem).then(response => {
        this.timesheets = response.data.data;
        this.last_page = response.data.last_page;
        this.total=response.data.total
      });
      getAllActiveUsers().then(response => {
        this.agents = response.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.timesheets.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDelete() {
      this.dialog = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  }
};
</script>
